import { setDoc, doc } from "firebase/firestore/lite"
import React, { useState, useCallback } from "react"
import { fs, storage } from "../../../utils/firebase"
import { getDownloadURL, ref, uploadBytes } from "firebase/storage"

const init = { serie: "", subtitle: "" }
export default function AddSerie() {
  const [newSerie, setNewSerie] = useState(init)

  const handleChange = useCallback(
    e => setNewSerie({ [e.target.name]: e.target.value }),
    []
  )
  const handleAddSerie = useCallback(
    () =>
      setDoc(doc(fs, "series", newSerie.serie), newSerie).then(() =>
        setNewSerie(init)
      ),
    [newSerie]
  )

  return (
    <div>
      <div>
        <label htmlFor="serie">serie</label>
        <input name="serie" value={newSerie.serie} onChange={handleChange} />
      </div>
      <div>
        <label htmlFor="subtitle">subtitle</label>
        <input
          name="subtitle"
          value={newSerie.subtitle}
          onChange={handleChange}
        />
      </div>
      {newSerie.serie && (
        <div>
          <label htmlFor="serie-banner">banner</label>
          <input
            onChange={e => {
              uploadBytes(
                ref(storage, `${newSerie.serie}/banner`),
                e.target.files[0]
              ).then(s =>
                getDownloadURL(s.ref).then(banner =>
                  setNewSerie(prev => ({ ...prev, banner }))
                )
              )
              // .catch(err => console.log({ err }))
            }}
            name="serie-banner"
            type={"file"}
            accept="image/*"
          />
        </div>
      )}
      <button onClick={handleAddSerie}>save</button>
    </div>
  )
}
