import React, { useCallback, useEffect, useMemo, useState } from "react"
import {
  getAuth,
  signInWithEmailAndPassword,
  setPersistence,
  browserLocalPersistence,
  signOut,
} from "firebase/auth"
import AddSerie from "./AddSerie"
import AddLocation from "./AddLocation"
import AddRecommendation from "./AddRecommendation"
import AddTour from "./AddTour"
import EditTour from "./EditTour"
import AddClient from "./AddClient"
import AddLecture from "./AddLecture"
import EditLecture from "./EditLecture"

import "../../styles/admin.scss"
import EditLocatin from "./EditLocation"
import EditSerie from "./EditSerie"

const fbAuth = getAuth()

export default function Admin() {
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")

  const tours = useMemo(
    () =>
      typeof window !== "undefined"
        ? JSON.parse(window?.localStorage.getItem("tours"))
        : [],
    []
  )

  const lectures = useMemo(
    () =>
      typeof window !== "undefined"
        ? JSON.parse(window?.localStorage.getItem("lectures"))
        : [],
    []
  )

  const locations = useMemo(
    () =>
      typeof window !== "undefined"
        ? JSON.parse(window?.localStorage.getItem("locations"))
        : [],
    []
  )

  const series = useMemo(
    () =>
      typeof window !== "undefined"
        ? JSON.parse(window?.localStorage.getItem("series"))
        : [],
    []
  )

  const handleReload = useCallback(
    () => typeof window !== "undefined" && window?.location.reload(),
    []
  )

  const handleLogin = useCallback(
    () =>
      setPersistence(fbAuth, browserLocalPersistence).then(
        () =>
          signInWithEmailAndPassword(fbAuth, email, password).then(() => {
            setEmail("")
            setPassword("")
          })
        // .catch(() => console.log("err login"))
      ),
    [email, password]
  )

  const handleLogout = useCallback(() => signOut(fbAuth).then(handleReload), [])

  return !fbAuth.currentUser ? (
    <div>
      <label htmlFor="email">email:</label>
      <input
        name="email"
        value={email}
        onChange={e => setEmail(e.target.value)}
      />
      <label htmlFor="password">password:</label>
      <input
        name="password"
        value={password}
        onChange={e => setPassword(e.target.value)}
      />
      <button onClick={handleLogin}>login</button>
    </div>
  ) : (
    <div className="admin-page">
      <button className="signout" onClick={handleLogout}>
        sign out
      </button>
      <Collapse children={<AddSerie />} title="add serie" />

      <Collapse children={<AddLocation />} title="add location" />

      <Collapse children={<AddRecommendation />} title="reccomendations" />

      <Collapse children={<AddClient />} title="clients" />

      <Collapse children={<AddTour />} title="add tour" />
      <Collapse children={<AddLecture />} title="add lecture" />
      <Collapse title="edit Location">
        {locations.map(l => (
          <Collapse
            title={`${l.location}`}
            key={l.location}
            children={<EditLocatin location={l} />}
          />
        ))}
      </Collapse>
      <Collapse title="edit serie">
        {series.map(s => (
          <Collapse
            title={`${s.serie}`}
            key={s.serie}
            children={<EditSerie serie={s} />}
          />
        ))}
      </Collapse>
      <Collapse title="edit Tour">
        {tours.map(t => (
          <Collapse
            children={<EditTour tour={t} />}
            title={`${t.title}`}
            key={t.title}
          />
        ))}
      </Collapse>
      <Collapse title="edit lecture">
        {lectures.map(l => (
          <Collapse
            children={<EditLecture lecture={l} />}
            title={`${l.title}`}
            key={l.title}
          />
        ))}
      </Collapse>
    </div>
  )
}

export { Head } from "../../components/Head"

function Collapse({ children, title }) {
  const [isOpen, setIsOpen] = useState(false)

  const toggleOpen = useCallback(() => setIsOpen(prev => !prev), [])
  const handleClickOutside = useCallback(
    e =>
      !document.getElementById(`${title}-toggle`)?.contains(e.target) &&
      toggleOpen(),
    []
  )
  useEffect(() => {
    isOpen
      ? window?.addEventListener("click", handleClickOutside, false)
      : window?.removeEventListener("click", handleClickOutside, false)
  }, [isOpen])

  return (
    <div className="toggle" id={`${title}-toggle`}>
      <p onClick={toggleOpen}>
        {title} <b>{isOpen ? `vv` : `>>`} </b>
      </p>
      <div className={`toggle-elem ${isOpen}`}> {isOpen && children}</div>
    </div>
  )
}
