import { doc, setDoc } from "firebase/firestore/lite"
import React, { useState, useCallback } from "react"
import { defaultLanguage, languages } from "../../../i18n"
import { fs, storage } from "../../../utils/firebase"
import { getDownloadURL, ref, uploadBytes } from "firebase/storage"

const initLoc = {
  location: "",
  lng: defaultLanguage,
  subtitle: "",
}

export default function AddLocation() {
  const [newLocation, setNewLocation] = useState(initLoc)

  const handleAddLocation = useCallback(
    () =>
      setDoc(doc(fs, "locations", newLocation.location), newLocation).then(() =>
        setNewLocation(initLoc)
      ),
    [newLocation]
  )

  return (
    <div>
      <div>
        <label htmlFor="loc-lng">lng: </label>
        <select
          id="loc-lng"
          name="loc-lng"
          onChange={e =>
            setNewLocation(prev => ({ ...prev, lng: e.target.value }))
          }
        >
          {languages.map(lng => (
            <option value={lng} key={`tour-${lng}`}>
              {lng}
            </option>
          ))}
        </select>
      </div>
      <div>
        <label htmlFor="new-location">location</label>
        <input
          name="new-location"
          value={newLocation.location}
          onChange={e =>
            setNewLocation(prev => ({ ...prev, location: e.target.value }))
          }
        />
      </div>
      <div>
        <label htmlFor="new-location-subtitle">subtitle</label>
        <input
          name="new-location-subtitle"
          value={newLocation.subtitle}
          onChange={e =>
            setNewLocation(prev => ({ ...prev, subtitle: e.target.value }))
          }
        />
      </div>
      {newLocation.location && (
        <>
          <div>
            <label htmlFor="loc-banner">banner</label>
            <input
              onChange={e => {
                uploadBytes(
                  ref(storage, `${newLocation.location}/banner`),
                  e.target.files[0]
                ).then(s =>
                  getDownloadURL(s.ref).then(banner =>
                    setNewLocation(prev => ({ ...prev, banner }))
                  )
                )
                // .catch(err => console.log({ err }))
              }}
              name="loc-banner"
              type={"file"}
              accept="image/*"
            />
          </div>
          <div>
            <label htmlFor="loc-img">img</label>
            <input
              onChange={e => {
                uploadBytes(
                  ref(storage, `${newLocation.location}/img`),
                  e.target.files[0]
                )
                  .then(s =>
                    getDownloadURL(s.ref).then(img =>
                      setNewLocation(prev => ({ ...prev, img }))
                    )
                  )
                  .catch(err => console.log({ err }))
              }}
              name="loc-img"
              type={"file"}
              accept="image/*"
            />
          </div>
        </>
      )}
      <button onClick={handleAddLocation}>save</button>
    </div>
  )
}
