import { setDoc, doc, deleteDoc } from "firebase/firestore/lite"
import React, { useCallback, useMemo } from "react"
import { fs, storage } from "../../../utils/firebase"
import {
  deleteObject,
  getDownloadURL,
  ref,
  uploadBytes,
} from "firebase/storage"

export default function AddClient() {
  const clients = useMemo(
    () =>
      typeof window !== "undefined"
        ? JSON.parse(window?.localStorage.getItem("clients"))
        : [],
    []
  )

  const handleAddClient = useCallback(
    client => setDoc(doc(fs, "clients", client.name), client),
    []
  )

  const handleDelete = useCallback(
    name =>
      deleteObject(ref(storage, `clients/${name}`)).then(() =>
        deleteDoc(doc(fs, "clients", name))
      ),
    []
  )

  return (
    <div>
      <p className="collapsed-p"> add client:</p>
      <div>
        <label htmlFor="client-img">img</label>
        <input
          onChange={e => {
            uploadBytes(
              ref(storage, `clients/${e.target.files[0].name}`),
              e.target.files[0]
            ).then(s =>
              getDownloadURL(s.ref).then(img =>
                handleAddClient({ name: e.target.files[0].name, img })
              )
            )
            // .catch(err => console.log({ err }))
          }}
          name="client-img"
          type={"file"}
          accept="image/*"
        />
      </div>
      <br />
      delete client:
      {clients.map(client => (
        <div key={client.name}>
          <img
            style={{ height: "40px", width: "40px" }}
            alt={client.name}
            src={client.img}
          />
          <button onClick={() => handleDelete(client.name)}>delete</button>
        </div>
      ))}
    </div>
  )
}
