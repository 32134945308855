import { setDoc, doc } from "firebase/firestore/lite"
import React, { useState, useCallback } from "react"
import { fs, storage } from "../../../utils/firebase"
import { getDownloadURL, ref, uploadBytes } from "firebase/storage"

export default function EditSerie({ serie }) {
  const [modifySerie, setModifySerie] = useState({ ...serie })
  const handleChange = useCallback(
    e => setModifySerie({ [e.target.name]: e.target.value }),
    []
  )
  const handleMerge = useCallback(
    () =>
      setDoc(doc(fs, "series", modifySerie.serie), modifySerie, {
        merge: true,
      })[modifySerie]
  )

  return (
    <div>
      <div>
        <label htmlFor="subtitle">subtitle</label>
        <input
          name="subitle"
          value={modifySerie.subtitle}
          onChange={handleChange}
        />
      </div>
      <div className="imgs-column">
        <p>banner</p>
        {modifySerie.banner && (
          <img className="edit-location-banner" src={modifySerie.banner} />
        )}
        <label htmlFor="serie-banner">change banner</label>
        <input
          onChange={e => {
            uploadBytes(
              ref(storage, `${modifySerie.serie}/banner`),
              e.target.files[0]
            ).then(s =>
              getDownloadURL(s.ref).then(banner =>
                setModifySerie(prev => ({ ...prev, banner }))
              )
            )
            // .catch(err => console.log({ err }))
          }}
          name="serie-banner"
          type={"file"}
          accept="image/*"
        />
      </div>
      <button onClick={handleMerge}>save</button>
    </div>
  )
}
