import { doc, setDoc } from "firebase/firestore/lite"
import { getDownloadURL, ref, uploadBytes } from "firebase/storage"
import React, { useState, useCallback, useMemo } from "react"
import { defaultLanguage, languages } from "../../../i18n"
import { fs, storage } from "../../../utils/firebase"

const initTour = {
  lng: defaultLanguage,
  title: "",
  serie: "",
  subtitle: "",
  location: "",
  content: "",
}

export default function AddTour() {
  const [newTour, setNewTour] = useState(initTour)

  const series = useMemo(
    () =>
      typeof window !== "undefined"
        ? JSON.parse(window?.localStorage.getItem("series"))
        : [],
    []
  )

  const locations = useMemo(
    () =>
      typeof window !== "undefined"
        ? JSON.parse(window?.localStorage.getItem("locations"))
        : [],
    []
  )
  const modifyNewTour = useCallback(
    e => setNewTour(prev => ({ ...prev, [e.target.name]: e.target.value })),
    []
  )

  const handleAddTour = useCallback(() => {
    setDoc(doc(fs, "tours", newTour.title), {
      ...newTour,
      dates: [],
      imgs: [],
    }).then(() => setNewTour(initTour))
  }, [newTour])

  return (
    <div>
      <div>
        <label htmlFor="lng">lng: </label>
        <select
          id="lng"
          name="lng"
          onChange={modifyNewTour}
          value={newTour.lng}
        >
          {languages.map(lng => (
            <option value={lng} key={`tour-${lng}`}>
              {lng}
            </option>
          ))}
        </select>
      </div>
      <div>
        <label htmlFor="serie">serie: </label>
        <select
          value={newTour.serie}
          onChange={modifyNewTour}
          id="serie"
          name="serie"
        >
          <option value={""} />
          {series.map(s => (
            <option key={s.serie} value={s.serie}>
              {s.serie}
            </option>
          ))}
        </select>
      </div>
      <div>
        <label htmlFor="location">location</label>
        <select
          value={newTour.location}
          onChange={modifyNewTour}
          id="location"
          name="location"
        >
          <option value={""} />
          {locations.map(loc => (
            <option key={loc.location} value={loc.location}>
              {loc.location}
            </option>
          ))}
        </select>
        <div>
          <label htmlFor="title">title</label>
          <input value={newTour.title} name="title" onChange={modifyNewTour} />
        </div>
        <div>
          <label htmlFor="subtitle">subtitle</label>
          <input
            name="subtitle"
            onChange={modifyNewTour}
            value={newTour.subtitle}
          />
        </div>
        <div>
          <label htmlFor="content">content</label>
          <input
            value={newTour.content}
            name="content"
            onChange={modifyNewTour}
          />
        </div>
      </div>
      <button onClick={handleAddTour}>save</button>
    </div>
  )
}
