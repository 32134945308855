import { doc, setDoc } from "firebase/firestore/lite"
import { getDownloadURL, ref, uploadBytes } from "firebase/storage"
import React, { useState, useCallback } from "react"
import { defaultLanguage, languages } from "../../../i18n"
import { fs, storage } from "../../../utils/firebase"

const init = {
  lng: defaultLanguage,
  title: "",
  subtitle: "",
  content: "",
}

export default function AddTour() {
  const [newLecture, setNewLecture] = useState(init)

  const modifyNewLecture = useCallback(
    e => setNewLecture(prev => ({ ...prev, [e.target.name]: e.target.value })),
    []
  )

  const handleAddLecture = useCallback(() => {
    setDoc(doc(fs, "lectures", newLecture.title), {
      ...newLecture,
      dates: [],
      imgs: [],
    }).then(() => setNewLecture(init))
  }, [newLecture])

  return (
    <div>
      <div>
        <label htmlFor="lng">lng: </label>
        <select
          id="lng"
          name="lng"
          onChange={modifyNewLecture}
          value={newLecture.lng}
        >
          {languages.map(lng => (
            <option value={lng} key={`tour-${lng}`}>
              {lng}
            </option>
          ))}
        </select>
      </div>
      <div>
        <div>
          <label htmlFor="title">title</label>
          <input
            value={newLecture.title}
            name="title"
            onChange={modifyNewLecture}
          />
        </div>
        <div>
          <label htmlFor="subtitle">subtitle</label>
          <input
            name="subtitle"
            onChange={modifyNewLecture}
            value={newLecture.subtitle}
          />
        </div>
        <div>
          <label htmlFor="content">content</label>
          <input
            value={newLecture.content}
            name="content"
            onChange={modifyNewLecture}
          />
        </div>
      </div>
      <button onClick={handleAddLecture}>save</button>
    </div>
  )
}
