import React from "react"

export function Head() {
  return (
    <>
      <meta charSet="utf-8" />
      <title>משה הררי - מורה דרך</title>
      <link rel="canonical" href="https://mosheharari.com" />
      <meta name="description" content={"משה הררי - מורה דרך"} />
      <meta name="og:title" content={"משה הררי - מורה דרך"} />
      <meta name="og:description" content={"משה הררי - מורה דרך"} />
      <meta name="og:type" content="website" />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:creator" content={"Hagai Harari YogiHa"} />
      <meta name="twitter:title" content={"משה הררי - מורה דרך"} />
      <meta name="twitter:description" content={"משה הררי - מורה דרך"} />
    </>
  )
}
